import React from 'react';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Banner,
  Skills,
  Projects,
  Experience,
  Education,
  Contact,
  Languages,
  Footer,
  NavigationBar
} from "./components"

import { useTranslation } from 'react-i18next';

function App() {

  return (
    <div className="App">
      <NavigationBar />
      <Banner />
      <Skills />
      <Projects />
      <Languages />
      <Experience />
      <Education />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
