
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png";
import icon0 from "../assets/img/icons/basic.png";
import icon1 from "../assets/img/icons/react.png";

import icon2 from "../assets/img/icons/laravel.png";
import icon3 from "../assets/img/icons/nodejs-1-logo.png";
import icon4 from "../assets/tech/bootstrap.png";
import icon5 from "../assets/img/icons/mongo.png";
import icon6 from "../assets/img/icons/mysql.png";
import icon7 from "../assets/img/icons/python.png";
import icon8 from "../assets/img/icons/agile.png";

import icon9 from "../assets/img/icons/sanity.png";
import icon10 from "../assets/img/icons/redux.png";

import icon11 from "../assets/img/icons/php.png";
import icon12 from "../assets/img/icons/git.png";


import icon13 from "../assets/tech/figma.png";
import icon14 from "../assets/img/icons/sass.png";
import icon15 from "../assets/img/icons/expressjs.png";
import icon16 from "../assets/tech/firebase.png";
import icon17 from "../assets/tech/vue.png";
import icon18 from "../assets/tech/github.png";
import icon19 from "../assets/tech/xampp.png";
import icon20 from "../assets/tech/tailwind.png";
import icon21 from "../assets/tech/docker.png";
import icon22 from "../assets/tech/slack.png";
import icon23 from "../assets/tech/dart.png";
import icon24 from "../assets/tech/netlify.png";
import icon25 from "../assets/tech/vercel.png";
import icon26 from "../assets/tech/npm.png";
import icon27 from "../assets/tech/yarn.png";
import Icon from './Icon';


export const Languages = () =>{

  return (
    <section className="languages" id="languages">
        <div className="container languages-bx mt-5 justify-content-center">
        <h2>Technologies and tools I use</h2>
            <div className="mt-3 p-1 text-center" style={{display:"flex-box"}}>
             <Icon image={icon0}/>
             <Icon image={icon1}/>
             <Icon image={icon9}/>
             <Icon image={icon10}/>
             <Icon image={icon2}/>
             <Icon image={icon3}/>
             <Icon image={icon4}/>
             <Icon image={icon5}/>
             <Icon image={icon6}/>
             <Icon image={icon11}/>
             <Icon image={icon7}/>
             <Icon image={icon8}/>
             <Icon image={icon12}/>
             <Icon image={icon13}/>
             <Icon image={icon14}/>
             <Icon image={icon15}/>
             <Icon image={icon16}/>
             <Icon image={icon17}/>
             <Icon image={icon18}/>
             <Icon image={icon19}/>
             <Icon image={icon20}/>
             <Icon image={icon21}/>
             <Icon image={icon22}/>
             <Icon image={icon23}/>
             <Icon image={icon24}/>
             <Icon image={icon25}/>
             <Icon image={icon26}/>
             <Icon image={icon27}/>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="gradient" />
    </section>
  )
}
