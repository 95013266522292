import projImg1 from "../assets/img/proj1.png";
import projImg2 from "../assets/img/proj2.png";
import projImg3 from "../assets/img/proj3.PNG";
import projImg4 from "../assets/img/proj4.png";
import projImg5 from "../assets/img/proj5.png";
import projImg6 from "../assets/img/proj6.png";
import projImg7 from "../assets/img/proj7.png";
import projImg8 from "../assets/img/proj8.png";
import projImg9 from "../assets/img/proj9.PNG";
import projImg10 from "../assets/img/proj10.PNG";
import projImg11 from "../assets/img/proj11.png";
import projImg12 from "../assets/img/projet12.png";


export const projects=[
    {
        title: "The Largest SAAS tools directory",
        description: "using MERN stack & Firebase & other Technologies",
        imgUrl: projImg12,
        src : {
          github : "#",
          web : "https://techbible.ai"
        },
        category : "React"
      },
    {
        title: "3D shirt customizer",
        description: "with React JS & Three JS",
        imgUrl: projImg8,
        src : {
          github : "https://github.com/Zorous/AI-Powered-3D-Website.git",
          web : "https://shirt-customizer.netlify.app/"
        },
        category : "React"
      },
    {
        title: "Private Real time chatroom",
        description: "with React JS & Firebase",
        imgUrl: projImg7,
        src : {
          github : "#",
          web : "https://open-chat-room.netlify.app"
        },
        category : "React"
      },
      
          {
            title: "Music Player",
            description: "Web Music Player with React JS",
            imgUrl: projImg2,
            src : {
              github : "https://github.com/Zorous/React-Js-Music-Player.git",
              web : "https://zorous-music-player.netlify.app/"
            },
            category : "React"
          },
    {
        title: "3D Portfolio",
        description: "with React JS & Three.js (may not support all the devices)",
        imgUrl: projImg9,
        src : {
          github : "#",
          web : "https://oussama-belhadi-3d-portfolio.netlify.app/"
        },
        category : "React"
      },
    {
        title: "Image Generator",
        description: "with React JS & OpenAI API",
        imgUrl: projImg10,
        src : {
          github : "https://github.com/Zorous/ai-image-text-generator",
          web : "https://ai-prompts.netlify.app/"
        },
        category : "React"
      },
    {
        title: "BooksClub",
        description: "with React JS & OpenLib API",
        imgUrl: projImg1,
        src : {
          github : "https://github.com/Zorous/React-JS-Books-App",
          web : "https://booksclub-app.netlify.app/"
        },
        category : "React"
      },
      {
        title: "Cryptocoins News",
        description: "using React JS and Redux Toolkit",
        imgUrl: projImg6,
        src : {
          github : "https://github.com/Zorous/crypto-react-app-CI-CD",
          web : "https://cryptocoins-world.netlify.app/"
        },
        category : "React"
      },
      {
        title: "E-SHOP",
        description: "using LARAVEL & MySQL,it includes second party users, local CMS and more",
        imgUrl: projImg11,
        src : {
          github : "https://github.com/Zorous/E-Shop",
          web : "#"
        },
        category : "Laravel"
      },
      {
        title: "CMMS",
        description: "computerized maintenance management system using LARAVEL & MySQL, it includes the mailing service and other features",
        imgUrl: projImg3,
        src : {
          github : "https://github.com/Zorous/Gestion-de-Maintenance-CMMS--with-LARAVEL",
          web : "#"
        },
        category : "Laravel"
      },
      {
        title: "Students Platform with a CV Generator (for client)",
        description: "using Basic HTML/CSS,JS,PHP and MySQL",
        imgUrl: projImg5,
        src : {
          github : "https://github.com/Zorous/Students_Plateform",
          web : "http://letudiantmarocain.com/"
        },
        category : "Basic"
      },
      {
        title: "Personal Portfolio V1",
        description: "using Basic HTML/Css/JS",
        imgUrl: projImg4,
        src : {
          github : "https://github.com/Zorous/Oussama_Belhadi-Porfolio",
          web : "https://zorous.netlify.app/"
        },
        category : "Basic"
      },
      {
        title: "Fingers gestures tracker",
        description: "with JavaScript and Tensorflow",
        imgUrl: "https://1.bp.blogspot.com/-KEhy1mzeiJI/YTqCNe1bfiI/AAAAAAAAKwQ/L4TNBRlJl3wZY9-F9zTgklswmIgs1gwigCLcBGAsYHQ/s0/figure%2B6.png ",
        src : {
          github : "https://github.com/Zorous/fingerpose_gestures_tracker",
          web : "https://fingers-gestures-tracker.netlify.app"
        },
        category : "Basic"
      },
  
];

