import React from 'react'
import loader from "../assets/img/anime-load.gif"

function Loader() {
  return (
    <div>
    <img src={loader} style={{width:"420px",marginLeft:"40%",alignItems:"center"}} alt="loading" />
    </div>
  )
}

export default Loader