import { Col } from "react-bootstrap";
import navIcon4 from '../assets/img/nav-icon4.svg';
import navIcon1 from '../assets/img/web-svgrepo-com.svg';

export const ProjectCard = ({ title, description, imgUrl, src }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt='cover' />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          <br/>
          <div className="social-icon">
          <a href={src.web} target="_blank" rel="noreferrer"><img src={navIcon1} alt="" /></a>
          <a href={src.github} target="_blank"  rel="noreferrer"><img src={navIcon4} alt="" /></a>
        </div>        </div>
      </div>
    </Col>
  )
}
