import React from 'react'

function Icon(props) {
  return (
    <span className='m-5'>
       <img src={props.image} className="language mt-4 mb-2" alt="tool(s)"/>            
    </span>
  )
}

export default Icon