import {
    javascript,
    typescript,
    html,
    css,
    reactjs,
    redux,
    tailwind,
    nodejs,
    mongodb,
    git,
    figma,
    agraf,
    techbible,
    docker,
    selfEmployee ,
    freelance,
    threejs,
  } from "../assets";
  
 
  const services = [
    {
      title: "Web Developer",
      icon: "web",
    },
    {
      title: "React Native Developer",
      icon: "mobile",
    },
    {
      title: "Backend Developer",
      icon: "backend",
    },
    {
      title: "Content Creator",
      icon: "creator",
    },
  ];
  
  const technologies = [
    {
      name: "HTML 5",
      icon: html,
    },
    {
      name: "CSS 3",
      icon: css,
    },
    {
      name: "JavaScript",
      icon: javascript,
    },
    {
      name: "TypeScript",
      icon: typescript,
    },
    {
      name: "React JS",
      icon: reactjs,
    },
    {
      name: "Redux Toolkit",
      icon: redux,
    },
    {
      name: "Tailwind CSS",
      icon: tailwind,
    },
    {
      name: "Node JS",
      icon: nodejs,
    },
    {
      name: "MongoDB",
      icon: mongodb,
    },
    {
      name: "Three JS",
      icon: threejs,
    },
    {
      name: "git",
      icon: git,
    },
    {
      name: "figma",
      icon: figma,
    },
    {
      name: "docker",
      icon: docker,
    },
  ];
  

/*   points: [
    "Developing and maintaining web applications using React.js and other related technologies.",
    "Collaborating with cross-functional teams including designers, product managers to create a high-quality product.",
    "Implementing responsive design and ensuring cross-browser compatibility.",
    "Participating in code reviews and providing constructive feedback to other developers.",
  ], */
  const experiences = [
    {
      title: "Fullstack Intern Developer",
      company_name: "Techbible - London",
      icon: techbible,
      iconBg: "#383E56",
      date: "April 2023",
      points: [
        "Developing and maintaining a web application using React.js, Firebase, MongoDB, express js, micro-services, different Cloud Providers and other related technologies.",
        "Practicing to deal with designing to enhance the UI/UX experience using Figma and other related tools.",
        "Participating in code reviews and providing constructive feedback to other developers.",
        "Actively participated in virtual meetings with advanced developers to discuss project requirements, brainstorm ideas, and share expertise.",
        "Contributed valuable insights and suggestions during team discussions, promoting innovation and problem-solving...",
      ],
    },
    {
      title: "Fullstack Intern Developer",
      company_name: "AGRTRAVIS - Fes",
      icon: agraf,
      iconBg: "#E6DEDD",
      date: "Octobre 2022 - Mars 2023",
      points: [
        "Developing and maintaining a web application using LARAVEL and other related technologies.",
        "Building a CMS like, Management System to enhance the traceability of the used materials",
        "Building a Privider's Loan Management System including printables (Excel, PDFs..)",
        "Implementing responsive design and ensuring cross-browser compatibility.",
        "Demonstrated strong interpersonal skills, fostering positive relationships with team members and establishing a culture of trust and cooperation.",
        "Successfully coordinated tasks and communicated progress to team members, ensuring transparency and accountability."
      ],
    },
    {
      title: "Fullstack Developer",
      company_name: "Freelance",
      icon: freelance ,
      iconBg: "#fff",
      date: "January 2022 - October 2022",
      points: [
        "Developing and maintaining web applications using React.js and other related technologies.",
        "Implementing responsive design and ensuring cross-browser compatibility.",
        "Participating in code reviews and providing constructive feedback to other developers.",
      ],
    }
  ];
  
  const testimonials = [
    {
      testimonial:
        "I thought it was impossible to make a website as beautiful as our product, but Rick proved me wrong.",
      name: "Sara Lee",
      designation: "CFO",
      company: "Acme Co",
      image: "https://randomuser.me/api/portraits/women/4.jpg",
    },
    {
      testimonial:
        "I've never met a web developer who truly cares about their clients' success like Rick does.",
      name: "Chris Brown",
      designation: "COO",
      company: "DEF Corp",
      image: "https://randomuser.me/api/portraits/men/5.jpg",
    },
    {
      testimonial:
        "After Rick optimized our website, our traffic increased by 50%. We can't thank them enough!",
      name: "Lisa Wang",
      designation: "CTO",
      company: "456 Enterprises",
      image: "https://randomuser.me/api/portraits/women/6.jpg",
    },
  ];
  
 
  
  export { services, technologies, experiences, testimonials };
  