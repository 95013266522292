import React from 'react'
import illustration from "../assets/img/lectures.PNG";

import { Container, Row,Col } from 'react-bootstrap'
import TrackVisibility from 'react-on-screen';
function Education() {
  return (
    <section className='education' id="education">
 <div class="container-fluid py-5" id="qualification">
 <div class="container">
   <div class="position-relative d-flex align-items-center justify-content-center">
     <h1 class="display-1 text-uppercase text-white">Education</h1>
   </div>
   <div class="row align-items-center">
     <div class="col-lg-6">
       <div class="border-left border-white pt-2 pl-4 ml-2">
    
       <div class="position-relative mb-4">
       <i class="far fa-dot-circle text-primary position-absolute" style={{top:"2px",left:"-32px"}}></i>
       <h5 class="font-weight-bold mb-1">Full-stack Developer Course <span class="frequently"><b>( Frequently . . . ) </b></span></h5>
       <p class="mb-2"><strong>IFMOTICA (Institut de formation des Métiers d'offshoring et des Techniques de la Communication, des Médias et de l'Audiovisuel)</strong> | <small>2021 - 2023</small></p>
       <p>learning all about web applications development using various of different frontend and backend languages and Frameworks</p>
     </div>
         <div class="position-relative mb-4">
           <i class="far fa-dot-circle text-primary position-absolute" style={{top:"2px",left:"-32px"}}></i>
           <h5 class="font-weight-bold mb-1">1st year on college</h5>
           <p class="mb-2"><strong>BTS - Brevet de téchnicien supérieur</strong> | <small>2020 - 2021</small></p>
           <p>Realization of a student thesis on the simulation of brain functions with electronic systems and programming of microprocessors in C language</p>
         </div>
         <div class="position-relative mb-4">
           <i class="far fa-dot-circle text-primary position-absolute" style={{top:"2px",left:"-32px"}}></i>
           <h5 class="font-weight-bold mb-1">High School Certification</h5>
           <p class="mb-2"><strong>Lycée Technique</strong> | <small>2019 - 2020</small></p>
           <p>general knowledge of electrical circuits, electronics, Industry, Low level programming in assembly</p>
         </div>
       </div>
     </div>
     <div class="col-lg-6">
     <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={illustration} alt="Header Img"/>
                </div>}
            </TrackVisibility>
       </div>
   </div>
 </div>
</div>
</section>
  )
}

export default Education